<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="ma-auto">
        <v-card outlined :loading="loading">
          <div class="px-8 pt-1 pb-12 text-center">
            <v-card-title class="text-center justify-center mb-3">
              <v-img
                  :src="require('@/assets/logo.png')"
                  alt="EndWait"
                  max-height="80"
                  max-width="120"
              />
            </v-card-title>
            <v-card-title
                class="text-center d-block font-weight-regular"
                style="line-height: 0.2rem;font-size: 24px"
            >
              Verify OTP
            </v-card-title>
            <v-card-title
                class="text-center d-block mb-5 font-weight-regular"
                style="line-height: inherit;font-size: 14px"
            >
              Enter the OTP sent to your email address
            </v-card-title>
            <v-card-text class="mt-8">
              <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                <form
                    @submit.prevent="handleSubmit(updatePassword)"
                    @reset.prevent="reset"
                >
                  <p class="mb-4" style="display: inline-block;">
                    <OtpInput
                        ref="otpInput"
                        input-classes="otp-input"
                        separator="-"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                    />
                  </p>

                  <ValidationProvider
                      v-slot="{ errors }"
                      name="password"
                      rules="required|min:5"
                  >
                    <v-text-field
                        v-model="password"
                        autocomplete="off"
                        :append-icon="
                        showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                        :error-messages="errors"
                        :type="showCurrentPassword ? 'text' : 'password'"
                        name="password"
                        label="New Password"
                        outlined
                        dense
                        class="input-group--focused mt-4"
                        @click:append="showCurrentPassword = !showCurrentPassword"
                    ></v-text-field>
                  </ValidationProvider>

                  <ValidationProvider
                      v-slot="{ errors }"
                      name="New Password and confirm password do not match"
                      rules="required|confirmed:password"
                  >
                    <v-text-field
                        v-model="confirmPassword"
                        autocomplete="off"
                        :append-icon="
                        showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                        :error-messages="errors"
                        :type="showCurrentPassword ? 'text' : 'password'"
                        name="confirmPassword"
                        label="Confirm Password"
                        outlined
                        dense
                        class="input-group--focused"
                        @click:append="showCurrentPassword = !showCurrentPassword"
                    ></v-text-field>

                  </ValidationProvider>
                  <p class="text-center">
                    <v-btn
                        type="submit"
                        class="deep-orange white--text"
                        :loading="loading"
                        depressed
                    >Update Password
                    </v-btn>
                  </p>
                  <div class="mt-2 d-flex justify-space-between">
                    <v-btn
                        text
                        small
                        class="text-capitalize subtitle-1"
                        color="deep-orange"
                        to="/signin"
                    >Sign in instead
                    </v-btn>

                    <v-btn
                        text
                        small
                        class="pl-0 text-capitalize subtitle-1"
                        color="deep-orange"
                        router
                        to="/signup"
                    >Create account
                    </v-btn>
                  </div>
                </form>
              </ValidationObserver>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import {Auth} from "aws-amplify";
import {mapGetters} from "vuex";
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "UpdatePassword",
  data: () => ({
    numInputs: {
      default: 6,
    },
    separator: {
      type: String,
      default: "**",
    },
    inputClasses: {
      type: String,
    },
    isInputNum: {
      type: Boolean,
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false,
    },
    showCurrentPassword: false,
    otp: "",
    email: "",
    password: "",
    confirmPassword: "",
    loading: false,
    snackbar: false,
    error: "",
    timeout: 3000,
  }),
  computed: {
    ...mapGetters(["getUrl"]),
  },
  created() {
    this.email = this.$route.params.id;
  },
  methods: {
    async updatePassword() {
      this.loading = true;
      try {
        await Auth.forgotPasswordSubmit(this.email, this.otp, this.password);

        this.error = "Password updated successfully";
        this.snackbar = true;
        setTimeout(() => {
          this.$router.push('/signin')
        }, this.timeout);
        this.$router.push('/signin')
      } catch (error) {

        this.error = error.message;
        this.snackbar = true;


      }
      this.loading = false;
    },
    redirect() {

    },
    handleOnComplete(value) {
      this.otp = value;
      // console.log('OTP completed: ', value);
    },
    handleOnChange(value) {
      this.otp = value;
      // console.log('OTP changed: ', value);
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    OtpInput
  },

};
</script>

<style>
.v-text-field--filled.v-input--dense.v-text-field--single-line
> .v-input__control
> .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined
> .v-input__control
> .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
> .v-input__control
> .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line
> .v-input__control
> .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined
> .v-input__control
> .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
> .v-input__control
> .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line
> .v-input__control
> .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined
> .v-input__control
> .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
> .v-input__control
> .v-input__slot {
  min-height: 35px;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 20px;
}

label.v-label.theme--light {
  font-size: 14px;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 5px;
  margin: 0 10px;
  font-size: 0.875rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  textalign: "center";
}

.error {
  border: 1px solid red !important;
}
</style>

<template>
    <v-container fluid class="fill-height">
        <v-row>
            <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="ma-auto">
                <v-card outlined :loading="loading">
                    <div class="px-8 pt-1 pb-12 text-center">
                        <v-card-title class="text-center justify-center mb-3">
                            <v-img
                                    :src="require('@/assets/logo.png')"
                                    alt="EndWait"
                                    max-height="80"
                                    max-width="120"
                            />
                        </v-card-title>
                        <v-card-title class="text-center d-block font-weight-regular"
                                      style="line-height: 0.2rem;font-size: 24px">
                            Confirm Sign up
                        </v-card-title>
                        <v-card-text class="mt-8">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                                <form
                                        @submit.prevent="handleSubmit(confirmSignUp)"
                                        @reset.prevent="reset"
                                >

                                    <ValidationProvider
                                            v-slot="{ errors }"
                                            name="Confirmation Code"
                                            rules="required|min:6"
                                    >
                                        <p class="mb-4" style="display: inline-block;">
                                            <v-otp-input
                                                    ref="otpInput"
                                                    v-model="otp"
                                                    input-classes="otp-input"
                                                    separator="-"
                                                    :error-messages="errors"
                                                    :num-inputs="6"
                                                    :should-auto-focus="true"
                                                    :is-input-num="true"
                                                    @on-change="handleOnChange"
                                                    @on-complete="handleOnComplete"
                                            />
                                        </p>
                                        <div class="v-card__title  d-block mb-5 font-weight-regular" style="line-height: inherit; font-size: 14px;"> OTP sent to below email address </div>
                                        <ValidationProvider
                                                v-slot="{ errors }"
                                                name="Email"
                                                rules="required|email"
                                        >
                                            <v-text-field
                                                    v-model="email"
                                                    :error-messages="errors"
                                                    label="Email"
                                                    class="mb-2 mt-4"
                                                    outlined
                                                    dense
                                                    disabled
                                            ></v-text-field>
                                        </ValidationProvider>

                                        <!--
                                          <v-text-field
                                                  v-model="code"
                                                  :error-messages="errors"
                                                  label="Confirmation Code"
                                                  class="mb-2"
                                                  outlined
                                                  dense
                                          ></v-text-field>
    -->
                                        <p class="text-right">
                                            <v-btn
                                                    type="submit"
                                                    class="deep-orange white--text"
                                                    :loading="loading"
                                                    depressed
                                            >Confirm
                                            </v-btn>
                                        </p>
                                    </ValidationProvider>


                                    <div class="mt-2 d-flex justify-space-between">
                                        <v-btn
                                                text
                                                small
                                                class="pl-0 text-capitalize subtitle-1"
                                                color="deep-orange"
                                                router
                                                @click="resendConfirmationCode"
                                        >Re-send confirmation code
                                        </v-btn>
                                    </div>
                                    <div class="mt-4 d-flex justify-center">
                                        <router-link to="/signin" class="black--text">
                                            Back to SignIn
                                        </router-link>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
                v-model="snackbar"
        >
            {{ error }}

            <template v-slot:action="{ attrs }">
                <v-btn
                        color="deep-orange deep-orange--text"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {Auth} from 'aws-amplify';

    export default {
        name: "ConfirmSignUp",
        data() {
            return {
                email: this.$store.getters.getConfirmEmail,
                otp: '',
                loading: false,
                snackbar: false,
                error: '',
                numInputs: {
                    default: 6,
                },
                separator: {
                    type: String,
                    default: "**",
                },
                inputClasses: {
                    type: String,
                },
                isInputNum: {
                    type: Boolean,
                },
                shouldAutoFocus: {
                    type: Boolean,
                    default: false,
                },
            }
        },
        created() {
            if (!this.$store.getters.getConfirmEmail) {
                this.$router.push('/signin')
            }
        },
        methods: {
            async confirmSignUp() {
                this.loading = true;
                try {
                    await Auth.confirmSignUp(this.email, this.otp);
                    this.$router.push('/signin')
                } catch (error) {
                    this.error = error.message;
                    this.snackbar = true
                }
                this.loading = false
            },
            async resendConfirmationCode() {
                this.loading = true;
                try {
                    await Auth.resendSignUp(this.email);
                    this.error = 'code resent successfully';
                    this.snackbar = true
                } catch (error) {
                    this.error = error.message;
                    this.snackbar = true
                }
                this.loading = false
            },

            handleOnComplete(value) {
                this.otp = value;
            },
            handleOnChange(value) {
                this.otp = value;
            },
            handleClearInput() {
                this.$refs.otpInput.clearInput();
            },

        }
    }
</script>

<style>

    .v-text-field--filled.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
    .v-text-field--filled.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
    .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
    .v-text-field--full-width.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
    .v-text-field--full-width.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
    .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot,
    .v-text-field--outlined.v-input--dense.v-text-field--single-line
    > .v-input__control
    > .v-input__slot,
    .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot,
    .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled
    > .v-input__control
    > .v-input__slot {
        min-height: 35px;
    }

    .otp-input {
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 5px;
        margin: 0 10px;
        font-size: 0.875rem;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        textalign: "center";
    }

    .v-text-field.v-text-field--solo .v-input__control {
        min-height: 20px;
    }
</style>

<template>
  <v-div class="fill-height">
    <v-row class="mt-5">
      <v-col cols="12" xs="12" sm="8" md="8" lg="7" class="ma-auto mt-5">
        <v-card class="" outlined :loading="loading">
          <v-row class="justify-space-between px-4 pt-3 p-12">
            <v-col cols="12" sm="12" md="6">

              <v-card-title class="font-weight-regular" style="line-height: 0.2rem;font-size: 24px">Join EndWait
                Family
              </v-card-title>

              <v-card-text class="mt-8">
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                  <form
                      @submit.prevent="handleSubmit(signUp)"
                      @reset.prevent="reset"
                  >
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required|min:3"
                    >
                      <v-text-field
                          v-model="name"
                          :error-messages="errors"
                          label="Name"
                          class="mb-2"
                          outlined
                          dense
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                    >
                      <v-text-field
                          v-model="email"
                          :error-messages="errors"
                          label="Email"
                          class="mb-2"
                          outlined
                          dense
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Phone"
                        rules="required|phone"
                        onclick={code}
                    >
                      <vue-tel-input-vuetify
                          v-model="phone"
                          :error-messages="errors"
                          label="Phone"
                          class="mb-6"
                          mode="international"
                          outlined
                          dense
                      >ee</vue-tel-input-vuetify>
                    </ValidationProvider>

                    <v-row>
                      <v-col cols="6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="Password"
                            rules="required|min:8|password:@confirm"
                        >
                          <v-text-field
                              v-model="password"
                              type="password"
                              :error-messages="errors"
                              label="Password"
                              outlined
                              dense
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="6">
                        <ValidationProvider
                            v-slot="{ errors }"
                            name="confirm"
                            rules="required"
                        >
                          <v-text-field
                              type="password"
                              v-model="confirmPassword"
                              :error-messages="errors"
                              label="Confirm"
                              outlined
                              dense
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12">
                        <ValidationProvider :rules="{ required_checkbox: { allowFalse: false } }" v-slot="{ errors }">
                          <v-checkbox type="checkbox" :error-messages="errors" id="terms" v-model="terms">
                            <template v-slot:label>
                              <div class="pt-2">
                                I have agreed to
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <a
                                        target="_blank"
                                        href="https://s3.ap-south-1.amazonaws.com/endwait.com/terms.html"
                                        @click.stop
                                        v-on="on"
                                    >
                                      Terms and Conditions
                                    </a>
                                  </template>
                                  Opens in new window
                                </v-tooltip>
                              </div>
                            </template>
                          </v-checkbox>
                        </ValidationProvider>
                      </v-col>
                    </v-row>

                    <div class="mt-6 d-flex justify-space-between">
                      <div style="position:relative; width:100%">
                        <router-link
                            style="position: absolute;top: 50%;  transform: translateY(-50%); display:inline;"
                            text
                            small
                            class="pl-0 text-capitalize deep-orange--text router-link-active subtitle-1"
                            router
                            to="signin"
                        >Sign in instead
                        </router-link
                        >
                        <v-btn
                            type="submit"
                            class="deep-orange white--text" style="float: right;"
                            :loading="loading"
                            depressed
                        >Sign up
                        </v-btn
                        >
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </v-card-text>

              <div class="mt-4 d-flex justify-center mb-4">
                <router-link to="/" class="deep-orange--text router-link-active">
                  Back to EndWait Home
                </router-link>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="align-self-center hidden-sm-and-down">
              <v-img
                  :src="require('@/assets/logo.png')"
                  alt="EndWait"
                  max-height="200"
                  max-width="250"
                  class="ma-auto"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar"
    >
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-div>
</template>

<script>
import {extend} from "vee-validate";
import PhoneNumber from 'awesome-phonenumber'
import {Auth} from "aws-amplify";


extend('phone', {
  message(fieldName) {
    return `${fieldName} is not a valid phone number`;
  },
  validate(value) {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value);
      resolve({valid: phone.isValid()})
    });
  }
});



export default {
  name: 'SignUp',
  data: () => ({
    email: '',
    phone: '',
    name: '',
    password: '',
    terms: false,
    confirmPassword: '',
    loading: false,
    snackbar: false,
    error: '',
  }),
  methods: {

    async signUp() {
      this.loading = true
      try {
        const phone = new PhoneNumber(this.phone)
        await Auth.signUp({
          username: this.email,
          password: this.password,
          phone:'',
          attributes: {
            email: this.email,
            phone_number: phone.getNumber('e164'),
            name: this.name,
          }
        });
        this.$store.commit('addConfirmEmail', this.email)
        this.$router.push({name: 'ConfirmSignUp'})
      } catch (error) {
        this.error = error.message
        this.snackbar = true
      }
      this.loading = false
    }
  }
}
</script>

<style>
.v-input__slot {
  height: 20px;
}

.v-input {
  font-size: 0.875rem !important;
}

.v-label {
  font-size: 14px;
}

.v-input--selection-controls {
  padding-top: 0;
  margin-top: 0;
}

.v-application a {
  color: #000000;
  text-decoration: none;
}
</style>
